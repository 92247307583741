import { computed, watch, ref } from 'vue';

const useHtmlElementOverflow = (container) => {
  const isOverflowing = ref(false);
  const visibleWidth = ref(0);
  const totalWidth = ref(0);
  const visibleItems = ref(0);
  const totalItems = ref(0);
  const itemWidth = ref(0);
  const observer = ref(null);

  watch(container, (newVal) => {
    if (!newVal) {
      if(observer.value) observer.value.disconnect();
      return;
    }

    setElementValues(newVal);
    if (!observer.value) {
      observer.value = new ResizeObserver(calculateElementValues)
      observer.value.observe(container.value)
    }
  });

  const calculateElementValues = (entries) => {
    for( const entry of entries) {
      const { target } = entry;
      setElementValues(target);
    }
  }

  const setElementValues = (containerElement) => {
    visibleWidth.value = containerElement.offsetWidth;
    totalWidth.value = containerElement.scrollWidth;
    isOverflowing.value = Math.abs(visibleWidth.value - totalWidth.value) > 2;
    totalItems.value = containerElement.children.length;
    itemWidth.value = getItemMaxWidth(containerElement);
    visibleItems.value = isOverflowing.value ? visibleWidth.value / itemWidth.value : totalItems.value;
  }


  const getItemMaxWidth = (container) => {
    return Array.from(container.children).reduce((maxWidth, item) => {
      const itemWidth = item.offsetWidth;
      return itemWidth > maxWidth ? itemWidth : maxWidth;
    }, 0);
  }

  const itemCssWidth = computed(() => `${itemWidth.value}px`);

  return {
    isOverflowing,
    visibleWidth,
    totalWidth,
    visibleItems,
    totalItems,
    itemWidth,
    itemCssWidth
  }
}

export default useHtmlElementOverflow;