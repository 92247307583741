/**
 * Delays the execution of the passed function until the current call stack is empty.
 * 
 * @param fn - Function that will be deferred.
 * @param inmediate - If true, the function will be executed inmediately.
 * @returns a promise that resolves with the result of the deferred function.
 */
const defer = (fn, inmediate = false) => {
    const deferredFn = (...args) => new Promise((resolve) => {
        setTimeout(() => {
            const res = fn(...args);
            resolve(res);
        });
    });

    return inmediate ? deferredFn() : deferredFn;
}

export { defer };